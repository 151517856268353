import React from 'react';
import {
    HomePageRandomJoke,
    HomePageStarredEntities,
    CustomHomepageGrid,
  } from '@backstage/plugin-home';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { MicrosoftCalendarCard } from '@backstage-community/plugin-microsoft-calendar';
  
export const HomePage = () => (
    <CustomHomepageGrid>
    // Insert the allowed widgets inside the grid
    <HomePageSearchBar />
    <HomePageRandomJoke />
    <MicrosoftCalendarCard />
    <HomePageStarredEntities />
  </CustomHomepageGrid>
);